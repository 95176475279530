.App {
  text-align: center;
  background: linear-gradient(90deg, #42275a 0%,  #734b6d 100%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: transparent;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-enter div {
  overflow-y: hidden;
  max-height: 0;
}

.fade-enter-active div {
  max-height: 50px;
  transition: height 1s;

}
.fade-exit div {
  max-height: 50px;
}
.fade-exit-active div {
  max-height: 0;
  overflow-y: hidden;
  transition: height 1s;
}

.div-table {
  width: 100%;
  padding: 0;
  display: table;

  font-size: 13px;
  border-collapse: separate;
  border-spacing: 1px;
}

.div-table-wrapper {
  overflow-x: auto;
  overflow-y: auto;
}

.rating-table-row {
  display: table-row;
}

.table-row-collapse.hidden .rating-table-row {
  display: none;
}

.table-row-collapse { 
  display: table-row-group !important;
  margin-bottom: 10px;
}
.table-row-collapse.visible {
  background-color: transparent;
  opacity: 0.5;
}
.table-row-collapse.visible .rating-table-row {
  animation: 0.1s accordion ease-out;
}

.rating-table-cell {
  display: table-cell;
  white-space: nowrap;
}

.rating-table-country {
  font-weight: 500;
  text-align: left;
  background-color: #fff;
  padding-left: 4px;
  position: sticky;
  left: 0;
  z-index: 2;
}

.rating-table-vintage {
  position: sticky;
  top: 0px;
  z-index: 1;
  font-weight: 500;
  background-color: #fff;
  padding: 4px;
  text-align: center;
}

.rating-critic {
  text-align: right;
  background-color: #fff;
  position: sticky;
  left: 0;
  z-index: 2;
  padding: 4px;
}

.rating-table-not-rated {
  text-align: center;
  font-weight: 300;
}

.rating-table-score {
  text-align: center;
  color: #FFF;
  font-weight: 500;
}

@keyframes accordion {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
  